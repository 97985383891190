import { styled } from "@mui/material";

export const ShowcaseGroupContainer = styled("div")(() => ({
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  gap: "32px",
  alignItems: "center",
  padding: "60px 40px",
  width: "90%",
  height: "auto",
  border: "1px solid #D8D8DC",
  boxShadow: "0 1px 3px rgba(0, 0, 0, 0.03), 0 2px 6px rgba(0, 0, 0, 0.03)",
  borderRadius: "6px",
}));

export const HorizontalShowcaseContainer = styled("div")(() => ({
  display: "flex",
  gap: "20px",
  width: "100%",
  overflowX: "scroll",
  padding: "1px 2px 10px 1px",
}));
