import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface MultiStepState {
  activeStep: number;
}

const initialState: MultiStepState = {
  activeStep: 2,
};

export const multiStepSlice = createSlice({
  name: "multiStep",
  initialState,
  reducers: {
    changeStep: (state, action: PayloadAction<number>) => {
      state.activeStep = action.payload;
    },
  },
});

export const { changeStep } = multiStepSlice.actions;

export default multiStepSlice.reducer;
