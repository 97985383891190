import "./AuthorItem.scoped.scss";

type TProps = {
  author: string;
  authorImage: string;
  preferedBook: string;
  description: string;
};

function AuthorItem({ author, authorImage, preferedBook, description }: TProps) {
  return (
    <div className="author-item">
      <div className="author-header">
        <img src={authorImage} className="author-image" alt="Jean Chen Ho" />
        <img src={preferedBook} className="author-preferred-book" alt="Preferred Book Ho" />
      </div>
      <div className="author-name">{author}</div>
      <div className="author-description">{description}</div>
    </div>
  );
}

export default AuthorItem;
