import { createSlice } from "@reduxjs/toolkit";
import { SwitchOptions, ShowcaseSetOptions, ShowcaseSet } from "type";
import { v4 as uuidv4 } from "uuid";

export interface Panel {
  id: string;
  title: string;
  image: {
    url: string;
    name: string;
  } | null;
  option: ShowcaseSet;
  showcase: string | null;
  link: string | null;
}

interface BlockSettings {
  id: number | null;
  title: string | null;
  [SwitchOptions.showBlockTitle]: boolean;
  panels: Panel[];
}

interface BlockPayload {
  attribute: keyof BlockSettings;
  data: string;
}

interface BlockPanelPayload {
  id: string;
  attribute: keyof Panel;
  data: any;
}

const initialBlockSettings: BlockSettings = {
  id: null,
  title: null,
  [SwitchOptions.showBlockTitle]: true,
  panels: [
    {
      id: uuidv4(),
      title: "Hero 1",
      image: null,
      option: ShowcaseSetOptions.all,
      showcase: null,
      link: null,
    },
    {
      id: uuidv4(),
      title: "Hero 2",
      image: null,
      option: ShowcaseSetOptions.all,
      showcase: null,
      link: null,
    },
    {
      id: uuidv4(),
      title: "Hero 3",
      image: null,
      option: ShowcaseSetOptions.all,
      showcase: null,
      link: null,
    },
    {
      id: uuidv4(),
      title: "Hero 4",
      image: null,
      option: ShowcaseSetOptions.all,
      showcase: null,
      link: null,
    },
  ],
};

export const heroCarouselSlice = createSlice({
  name: "heroCarouselSlice",
  initialState: initialBlockSettings,
  reducers: {
    updateBlockSettings: (state, action) => {
      const { attribute, data } = action.payload as BlockPayload;
      if (attribute === "title") {
        state[attribute] = data;
      }
      if (attribute === SwitchOptions.showBlockTitle) {
        state[attribute] = !state[attribute];
      }
    },
    updatePanelSettings: (state, action) => {
      const { id, attribute, data } = action.payload as BlockPanelPayload;
      const item = state.panels.find((panel) => panel.id === id);
      if (!item) {
        return;
      }
      item[attribute] = data;
    },
    addPanel: (state) => {
      state.panels.push({
        id: uuidv4(),
        title: `Hero ${state.panels.length + 1}`,
        image: null,
        option: ShowcaseSetOptions.all,
        showcase: null,
        link: null,
      });
    },
  },
});

export const { updateBlockSettings, updatePanelSettings, addPanel } = heroCarouselSlice.actions;

export default heroCarouselSlice.reducer;
