export const CoverPillMockData = [
  {
    id: 1,
    images: [
      "https://books.google.com/books/content?vid=ISBN0292730853&printsec=frontcover&img=1&zoom=1",
      "https://books.google.com/books/content?vid=ISBN0684195577&printsec=frontcover&img=1&zoom=1",
      "https://books.google.com/books/content?vid=ISBN0292730853&printsec=frontcover&img=1&zoom=1",
    ],
    title: "",
  },
  {
    id: 2,
    images: [
      "https://books.google.com/books/content?vid=ISBN7503412097&printsec=frontcover&img=1&zoom=1",
      "https://books.google.com/books/content?vid=ISBN0375705201&printsec=frontcover&img=1&zoom=1",
      "https://books.google.com/books/content?vid=ISBN0684195577&printsec=frontcover&img=1&zoom=1",
    ],
    title: "",
  },
  {
    id: 3,
    images: [
      "https://books.google.com/books/content?vid=ISBN0292730853&printsec=frontcover&img=1&zoom=1",
      "https://books.google.com/books/content?vid=ISBN7503412097&printsec=frontcover&img=1&zoom=1",
      "https://books.google.com/books/content?vid=ISBN0375705201&printsec=frontcover&img=1&zoom=1",
    ],
    title: "",
  },
];

export const ImagePillsMockData = [
  {
    id: 1,
    image: "",
    title: "",
    overlay: {
      name: "Blue Tint",
      color: "#2D61ADC2",
    },
  },
  {
    id: 2,
    image: "",
    title: "",
    overlay: {
      name: "Blue Tint",
      color: "#2D61ADC2",
    },
  },
  {
    id: 3,
    image: "",
    title: "",
    overlay: {
      name: "Blue Tint",
      color: "#2D61ADC2",
    },
  },
  {
    id: 4,
    image: "",
    title: "",
    overlay: {
      name: "Blue Tint",
      color: "#2D61ADC2",
    },
  },
];

export const CollagePanelMockData = [
  {
    id: 1,
    images: [
      "https://books.google.com/books/content?vid=ISBN7503412097&printsec=frontcover&img=1&zoom=1",
      "https://books.google.com/books/content?vid=ISBN0375705201&printsec=frontcover&img=1&zoom=1",
      "https://books.google.com/books/content?vid=ISBN0684195577&printsec=frontcover&img=1&zoom=1",
      "https://books.google.com/books/content?vid=ISBN0292730853&printsec=frontcover&img=1&zoom=1",
      "https://books.google.com/books/content?vid=ISBN0684195577&printsec=frontcover&img=1&zoom=1",
      "https://books.google.com/books/content?vid=ISBN0292730853&printsec=frontcover&img=1&zoom=1",
      "https://books.google.com/books/content?vid=ISBN0292730853&printsec=frontcover&img=1&zoom=1",
      "https://books.google.com/books/content?vid=ISBN7503412097&printsec=frontcover&img=1&zoom=1",
    ],
    title: "",
    description: "",
  },
  {
    id: 2,
    images: [
      "https://books.google.com/books/content?vid=ISBN7503412097&printsec=frontcover&img=1&zoom=1",
      "https://books.google.com/books/content?vid=ISBN0375705201&printsec=frontcover&img=1&zoom=1",
      "https://books.google.com/books/content?vid=ISBN0684195577&printsec=frontcover&img=1&zoom=1",
      "https://books.google.com/books/content?vid=ISBN0292730853&printsec=frontcover&img=1&zoom=1",
      "https://books.google.com/books/content?vid=ISBN0684195577&printsec=frontcover&img=1&zoom=1",
      "https://books.google.com/books/content?vid=ISBN0292730853&printsec=frontcover&img=1&zoom=1",
      "https://books.google.com/books/content?vid=ISBN0292730853&printsec=frontcover&img=1&zoom=1",
      "https://books.google.com/books/content?vid=ISBN7503412097&printsec=frontcover&img=1&zoom=1",
    ],
    title: "",
    description: "",
  },
  {
    id: 3,
    images: [
      "https://books.google.com/books/content?vid=ISBN7503412097&printsec=frontcover&img=1&zoom=1",
      "https://books.google.com/books/content?vid=ISBN0375705201&printsec=frontcover&img=1&zoom=1",
      "https://books.google.com/books/content?vid=ISBN0684195577&printsec=frontcover&img=1&zoom=1",
      "https://books.google.com/books/content?vid=ISBN0292730853&printsec=frontcover&img=1&zoom=1",
      "https://books.google.com/books/content?vid=ISBN0684195577&printsec=frontcover&img=1&zoom=1",
      "https://books.google.com/books/content?vid=ISBN0292730853&printsec=frontcover&img=1&zoom=1",
      "https://books.google.com/books/content?vid=ISBN0292730853&printsec=frontcover&img=1&zoom=1",
      "https://books.google.com/books/content?vid=ISBN7503412097&printsec=frontcover&img=1&zoom=1",
    ],
    title: "",
    description: "",
  },
];

export const ImagePanelsMockData = [
  {
    id: 1,
    image: "",
    title: "",
    description: "Description comes here dummy text here",
  },
  {
    id: 2,
    image: "",
    title: "",
    description: "Description comes here dummy text here",
  },
  {
    id: 3,
    image: "",
    title: "",
    description: "Description comes here dummy text here",
  },
];
