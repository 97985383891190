import { Container, styled } from "@mui/material";
import { useAppSelector } from "app/hooks";
import { RootState } from "app/store";
import Preview from "pages/Preview";

const LayoutRoot = styled("div")(() => ({
  display: "flex",
  flex: 1,
  flexDirection: "column",
}));

const Main = () => {
  const show = useAppSelector((state: RootState) => state.sidenav.show);
  const containerType = show ? false : "lg";

  return (
    <LayoutRoot>
      <Container maxWidth={containerType} sx={{ display: show ? "flex" : "block" }}>

      <Preview />
      </Container>
    </LayoutRoot>
  );
};

export default Main;
