import { createSlice } from "@reduxjs/toolkit";

export interface ShowSideNavState {
  show: boolean;
}

const initialState: ShowSideNavState = {
  show: true,
};

export const showSideNavSlice = createSlice({
  name: "sideNav",
  initialState,
  reducers: {
    showSidenav: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.show = true;
    },
    hideSidenav: (state) => {
      state.show = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { showSidenav, hideSidenav } = showSideNavSlice.actions;

export default showSideNavSlice.reducer;
