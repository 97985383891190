import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const bookApiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://63565b87a2d1844a9772b8a0.mockapi.io/api/filters/",
  }),
  endpoints(builder) {
    return {
      fetchBooks: builder.query<
        any,
        {
          limit: number;
          page: number;
          filterSelected?: any | [];
          search?: null | string;
          sortSelected?: string;
        }
      >({
        query(params) {
          const { page, limit, filterSelected, search, sortSelected } = params;
          if (search !== null && filterSelected?.length > 0) {
            return `/books?}&page=${page}&limit=${limit}&search=${search}&filter=${filterSelected}&sort=${sortSelected}`;
          }
          if (search !== null && filterSelected?.length === 0) {
            return `/books?page=${page}&limit=${limit}&search=${search}&sort=${sortSelected}`;
          }
          if (search === null && filterSelected?.length > 0) {
            return `/books?page=${page}&limit=${limit}&filter=${filterSelected}&sort=${sortSelected}`;
          }
          return `/books?page=${page}&limit=${limit}&sort=${sortSelected}`;
        },
      }),
    };
  },
});

export const { useLazyFetchBooksQuery } = bookApiSlice;
