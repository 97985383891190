import { styled } from "@mui/material";

const TextContainer = styled("div")(() => ({
  width: "100%",
  height: "auto",
  padding: "0 12px 12px 12px",
  display: "flex",
  flexDirection: "column",
  gap: "8px",
}));

export default TextContainer;
