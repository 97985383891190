import { Search as SearchIcon } from "@mui/icons-material";
import { styled, TextField, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import * as React from "react";

const TextFieldCustom = styled(TextField)(() => ({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  "& .MuiInputBase-root.Mui-disabled": {
    borderColor: "unset",
    borderStyle: "unset",
    borderWidth: 0,
  },
}));

export default function Search() {
  const theme = useTheme();
  return (
    <Box
      sx={{
        marginBottom: "50px",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          maxWidth: 550,
          width: "100%",
          display: "flex",
          alignItems: "flex-end",
          borderRadius: 0.5,
          overflow: "hidden",
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: theme.palette.grey[200],
        }}
      >
        <SearchIcon
          sx={{
            backgroundColor: theme.palette.grey[400],
            color: theme.palette.common.white,
            padding: 0.5,
            width: "48.5px",
            height: "100%",
          }}
        />
        <TextFieldCustom
          sx={{
            borderColor: "unset",
            backgroundColor: theme.palette.common.white,
            padding: "15.4px",
            margin: 0,
            "& .MuiInputBase-root.Mui-disabled": {
              padding: 0,
              margin: 0,
              borderColor: "unset",
              borderStyle: "unset",
              borderWidth: 0,
              "> input": {
                padding: 0,
                margin: 0,
                borderColor: "unset",
                borderStyle: "unset",
                borderWidth: 0,
              },
              "&:before, &:after": {
                borderColor: "unset",
                borderStyle: "unset",
                borderWidth: 0,
              },
            },
          }}
          disabled
          id="input-with-sx"
          variant="standard"
        />
      </Box>
    </Box>
  );
}
