/* eslint-disable no-nested-ternary */
import { Box, Typography, styled, useTheme } from "@mui/material";
import PreviewPageMock from "__mocks__/previewPageMockData.json";
import { DisplayType, Mode } from "pages/CoverCarousel";
import { HorizontalShowcaseContainer } from "styles/common/ShowcaseGroup";
import { A11y, Navigation } from "swiper";

import PageBlock from "components/PageBlock/PageBlock";
import TitleItem from "components/TitleItem/TitleItem";

import HeroCarousel from "../HeroCarousel/HeroCarousel";
import Search from "../Search";
import PreViewHeader from "./PreivewHeader";

const LayoutRoot = styled("div")(() => ({
  display: "flex",
  flex: 1,
  flexDirection: "column",
}));

function PreviewPage() {
  const theme = useTheme();

  return (
    <LayoutRoot>
      <Box
        sx={{
          marginTop: 3,
          display: "flex",
          marginBottom: 5,
          paddingBottom: 5,
          flexDirection: "column",
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: theme.palette.grey[200],
        }}
      >
        <PreViewHeader />
        <Box
          sx={{
            marginTop: "50px",
          }}
        >
          <Search />
        </Box>

        <Box
          sx={{
            paddingLeft: 5,
            paddingRight: 5,
          }}
        >
          {PreviewPageMock.page_block.map((block) => (
            <Box sx={{ marginBottom: "40px" }}>
              <Typography variant="h2" component="h2" sx={{ marginBottom: "30px" }}>
                {block.block_title}
              </Typography>
              {block.block_type.block_type_description === "cover-carousel" ? (
                <HeroCarousel
                  modules={[Navigation, A11y]}
                  slidesPerView={6}
                  spaceBetween={30}
                  // eslint-disable-next-line react/jsx-boolean-value
                  navigation={true}
                >
                  {block.page_block_item?.map((item) => {
                    const { author, coverUrl } = item;
                    return (
                      <TitleItem
                        title={block.block_title}
                        author={author}
                        coverUrl={coverUrl}
                        displayType={DisplayType.plain}
                        mode={Mode.preview}
                      />
                    );
                  })}
                </HeroCarousel>
              ) : block.block_type.block_type_description === "hero-carousel" ? (
                <HeroCarousel
                  modules={[A11y]}
                  slidesPerView={1}
                  spaceBetween={1}
                  navigation={false}
                >
                  {block.page_block_item?.map((image) => (
                    <img src={image.image} alt={image.title} />
                  ))}
                </HeroCarousel>
              ) : (
                <HorizontalShowcaseContainer>
                  <PageBlock
                    blockType={block.block_type.block_type_description}
                    data={block.page_block_item}
                    mode={Mode.preview}
                    showTitleOfShowcases={block.show_showcase_title}
                    showShowcaseDescription={block.show_showcase_description}
                  />
                </HorizontalShowcaseContainer>
              )}
            </Box>
          ))}
        </Box>
      </Box>
    </LayoutRoot>
  );
}

export default PreviewPage;
