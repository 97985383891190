import { Stack } from "@mui/material";
import BookListMock from "__mocks__/books.json";
import dummySearch from "__mocks__/dummyJson";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { ReactComponent as Plain } from "assests/icons/display-type-plain.svg";
import { ReactComponent as Styled } from "assests/icons/display-type-styled.svg";
import PageView from "layouts/PageView";
import RightSidebar from "layouts/RightSidebar";
import { useEffect, useState } from "react";
import {
  updateBookSearched,
  updateDisplayConfig,
  updateDisplayType,
  updateShowCaseSelected,
  updateShowCaseTitle,
} from "store/coverCarouselSlice";
import { showSidenav } from "store/sideNavController";

import RadioButton from "components/Buttons/RadioButton/RadioButton";
import CarouselContainer from "components/Carousel/CarouselContainer";
import SmallCard from "components/CustomCards/SmallCard/SmallCard";
import Input from "components/Input/Input";
import SearchDialog from "components/SearchDialog/SearchDialog";
import SectionTitle from "components/SectionTitle/SectionTitle";
import SwitchSelector from "components/SwitchSelector/SwitchSelector";
import TypeAheadDropdown from "components/TypeAheadDropdown/TypeAheadDropdown";

export enum DisplayType {
  plain = "plain",
  styled = "styled",
}

export enum Mode {
  draft = "draft",
  preview = "preview",
}

enum SwitchOptions {
  showBlockTitle = "showBlockTitle",
  showResourceTitle = "showResourceTitle",
  showContributorName = "showContributorName",
}

type DisplayTypeSettings = {
  type: DisplayType;
  title: string;
  icon: any;
};

interface BlockSettingsType {
  [DisplayType.plain]: DisplayTypeSettings;
  [DisplayType.styled]: DisplayTypeSettings;
}

const initialBlockSettings: BlockSettingsType = {
  [DisplayType.plain]: {
    type: DisplayType.plain,
    title: "Plain",
    icon: Plain,
  },
  [DisplayType.styled]: {
    type: DisplayType.styled,
    title: "Styled",
    icon: Styled,
  },
};

enum ShowcaseSets {
  all = "all",
  myShowcases = "myShowcases",
}

type ShowcaseSettings = {
  [key in ShowcaseSets]?: {
    type: ShowcaseSets;
    title: string;
  };
};

const initialShowcaseSettings: ShowcaseSettings = {
  [ShowcaseSets.all]: {
    type: ShowcaseSets.all,
    title: "All",
  },
  [ShowcaseSets.myShowcases]: {
    type: ShowcaseSets.myShowcases,
    title: "My Showcases",
  },
};

function CoverCarousel() {
  const dispatch = useAppDispatch();
  const [results, setResults] = useState([]);
  const [dropdownOpened, setDropdownOpened] = useState<boolean>(false);
  const [searchOpened, setSearchOpened] = useState<boolean>(false);
  const { displayType, showCaseSelected, bookSearched, showCaseTitle, displayConfig } =
    useAppSelector((state) => state.coverCarousel);

  useEffect(() => {
    setTimeout(() => {
      dummySearch(bookSearched).then(({ products }) => {
        setResults(products);
      });
    }, 1000);
  }, [bookSearched]);

  useEffect(() => {
    dispatch(showSidenav());
  }, [displayType]);

  const onSelect = (item: any) => {
    if (item === "action") {
      setSearchOpened(true);
    }
  };

  const toggleOption = (option: SwitchOptions) => {
    dispatch(updateDisplayConfig(option));
  };

  const handleShowCaseTitle = (e: any) => {
    dispatch(updateShowCaseTitle(e));
  };

  const onDropdownSelect = (item: any) => {
    dispatch(updateBookSearched(item.target.innerText));
  };

  const handleChangeDisplayType = (type: DisplayType) => {
    dispatch(updateDisplayType(type));
    dispatch(updateDisplayConfig("reset"));
  };

  return (
    <>
      <PageView>
        <CarouselContainer list={BookListMock} />
      </PageView>
      <RightSidebar>
        <SectionTitle>Select Display Type</SectionTitle>
        <Stack direction="row" spacing="24px" sx={{ width: "100%" }}>
          {Object.values(initialBlockSettings).map((item) => (
            <SmallCard
              title={item.title}
              icon={item.icon}
              isFill
              isActive={displayType === item.type}
              onClick={() => handleChangeDisplayType(item.type)}
            />
          ))}
        </Stack>
        <SectionTitle>Select Showcase</SectionTitle>
        <Stack direction="row" spacing="80px" sx={{ width: "100%", fontSize: "14px" }}>
          {Object.values(initialShowcaseSettings).map(({ type, title }) => (
            <RadioButton
              title={title}
              checked={showCaseSelected === type}
              onClick={() => dispatch(updateShowCaseSelected(type))}
            />
          ))}
        </Stack>

        <TypeAheadDropdown
          dropdownOpened={dropdownOpened}
          setDropdownOpened={setDropdownOpened}
          placeholder="Search a book"
          hasSuggestions
          onChange={(e) => dispatch(updateBookSearched(e))}
          value={bookSearched}
          suggestions={results}
          onSelect={onDropdownSelect}
        />
        <SectionTitle>Showcase Block Settings</SectionTitle>
        <Input
          placeholder="Enter title"
          onChange={handleShowCaseTitle}
          label="Title of showcase block"
          value={showCaseTitle}
        />
        <SwitchSelector
          label="Show title of showcase block?"
          defaultChecked={displayConfig?.showBlockTitle}
          onSwitch={() => toggleOption(SwitchOptions.showBlockTitle)}
        />
        <SwitchSelector
          label="Show resource titles?"
          defaultChecked={displayConfig?.showResourceTitle}
          onSwitch={() => toggleOption(SwitchOptions.showResourceTitle)}
        />
        <SwitchSelector
          label="Show contributor names?"
          defaultChecked={displayConfig?.showContributorName}
          onSwitch={() => toggleOption(SwitchOptions.showContributorName)}
        />
      </RightSidebar>
      <SearchDialog
        open={searchOpened}
        onClose={() => setSearchOpened(false)}
        onSelect={onSelect}
      />
    </>
  );
}

export default CoverCarousel;
