export const colors = {
  primary: "#0075E3",
  blue: "#2356F6",
  secondary: "#868B9F",
  danger: "#F03738",
  purple: "#414083",
  purpleLight: "#E0DFF5",
  white: "#fff",
  black: "#000",
  dark: "#262B2B",
  lightGray: "#FAFAFA",
  borderColor: "#E8EEF3",
  yellow: "#F3BB1C",
  green: "#11865D",
  greenLighter: "#F3FBF8",
  warning: "#FFCD89",
};

export default colors;
