import { Action, ThunkAction, configureStore } from "@reduxjs/toolkit";
import activePillReducer from "store/activePillSlice";
import { apiSlice } from "store/api-slice";
import { bookApiSlice } from "store/book-api-slice";
import counterReducer from "store/counterSlice";
import coverCarouselSliceReducer from "store/coverCarouselSlice";
import heroCarouselReducer from "store/heroCarouselSlice";
import homeConfigurationReducer from "store/homeConfiguration";
import multiStepReducer from "store/multiStepSlice";
import showcaseTypesReducer from "store/showcaseTypesSlice";
import sideNavReducer from "store/sideNavController";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    showcaseTypes: showcaseTypesReducer,
    multiStep: multiStepReducer,
    activePill: activePillReducer,
    sidenav: sideNavReducer,
    homePage: homeConfigurationReducer,
    heroCarousel: heroCarouselReducer,
    coverCarousel: coverCarouselSliceReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
    [bookApiSlice.reducerPath]: bookApiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(bookApiSlice.middleware).concat(apiSlice.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
