import { Box } from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { RootState } from "app/store";
import { ReactComponent as CheckIcon } from "assests/icons/check.svg";
import { useEffect } from "react";
import { changeStep } from "store/multiStepSlice";
import { PageStatus } from "type";

import "./Multistep.scoped.scss";

interface Props {
  sx?: Object;
}

interface Step {
  number: number;
  title: string;
  status: PageStatus;
}

function MultiStep({ sx }: Props) {
  const dispatch = useAppDispatch();
  const steps: Step[] = [
    { number: 1, title: "Draft", status: PageStatus.EMPTY },
    { number: 2, title: "Preview", status: PageStatus.DRAFT },
    { number: 3, title: "Publish", status: PageStatus.PUBLISHED },
  ];

  const activeStep = useAppSelector((state: RootState) => state.multiStep.activeStep);
  const status = useAppSelector((state: RootState) => state.homePage.status);

  useEffect(() => {
    const findStep = steps.find((step) => step.status === status);
    if (findStep) {
      dispatch(changeStep(findStep.number));
    }
  }, [status]);

  return (
    <Box className="multistep-container" sx={sx}>
      {steps.map((step, i) => {
        const isEqualToLength = i === steps.length - 1;
        const isActive = i + 1 === activeStep;
        const isCompleted = i + 1 < activeStep;

        return (
          <div className="step-title-wrapper" key={i}>
            <div
              data-cy={`element-${i}`}
              className={`step-title-container ${isActive && "step-active"} ${
                isCompleted && "step-completed"
              }`}
            >
              <div className="step">{isCompleted ? <CheckIcon /> : step.number}</div>
              <div className="title">{step.title}</div>
            </div>
            {!isEqualToLength && <div className={`line ${isCompleted && "active-line"}`} />}
          </div>
        );
      })}
    </Box>
  );
}

export default MultiStep;
