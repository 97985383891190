import { Typography, styled } from "@mui/material";
import PicturePlaceholder from "assests/icons/picture-placeholder.svg";
import { Mode } from "pages/CoverCarousel";

type TProps = {
  images?: string[];
  title?: string;
  mode?: string;
};

const CoverPillContainer = styled("div")(({ className }) => ({
  background: "#FFFFFF",
  boxShadow: "0px 0.803949px 4.01975px #DBDBDB",
  borderRadius: "2.41185px",
  display: "flex",
  padding: "8px",
  width: "300px",
  height: "100px",
  alignItems: "center",
  ...(className?.includes(Mode.draft) && {
    width: "176px",
    height: "80px",
    gap: "15px",
  }),
  ...(className?.includes(Mode.preview) && {
    width: "300px",
    height: "100px",
    gap: "18px",
  }),
}));

const ImagesContainer = styled("div")(() => ({
  display: "flex",
  gap: 0,
}));

const ImageContainer = styled("div")(({ className }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  padding: 0,
  background: "#E2F1FA",
  ...(className?.includes(Mode.draft) && {
    width: "30.67px",
    height: "64px",
    border: "0.630192px dashed #AAD5EE",
    borderRadius: "0px 1.68051px 1.68051px 0px",
  }),
  ...(className?.includes(Mode.preview) && {
    width: "53px",
    height: "81px",
  }),
}));

const Image = styled("img")(({ className }) => ({
  ...(className?.includes(Mode.preview) && {
    width: "100%",
    height: "100%",
    contain: "cover",
  }),
}));

const CoverPill = ({ images, mode, title }: TProps) => (
  <CoverPillContainer className={`${mode}`}>
    <ImagesContainer>
      {images?.map((image, index) => (
        <ImageContainer key={index} className={`${mode}`}>
          <Image
            className={`${mode}`}
            src={mode === Mode.draft ? PicturePlaceholder : image}
            alt=""
          />
        </ImageContainer>
      ))}
    </ImagesContainer>
    <Typography variant={mode === Mode.draft ? "subtitle1" : "h5"}>
      {mode === Mode.preview ? title : "Title"}
    </Typography>
  </CoverPillContainer>
);

export default CoverPill;
