import { Typography, styled } from "@mui/material";
import PicturePlaceholder from "assests/icons/picture-placeholder.svg";
import { Mode } from "pages/CoverCarousel";
import React from "react";
import TextContainer from "styles/common/TextContainer";

type TProps = {
  description: string;
  showTitleOfShowcases?: boolean;
  showShowcaseDescription?: boolean;
  mode: string;
  image?: string;
  title?: string;
};

const ImagePanelContainer = styled("div")(({ className }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "16px",
  height: "auto",
  background: "#FFFFFF",
  ...(className?.includes(Mode.draft) && {
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.15)",
    width: "196px",
  }),
  ...(className?.includes(Mode.preview) && {
    width: "297px",
  }),
}));
const ImageContainer = styled("div")(({ className }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  padding: 0,
  background: "#E2F1FA",
  border: "0.630192px dashed #AAD5EE",
  borderRadius: "1.68051px",
  ...(className?.includes(Mode.draft) && {
    width: "196px",
    minHeight: "185px",
  }),
  ...(className?.includes(Mode.preview) && {
    width: "297px",
    height: "198px",
  }),
}));

const Image = styled("img")(({ className }) => ({
  ...(className?.includes(Mode.preview) && {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  }),
}));

const ImagePanels = ({
  description,
  showTitleOfShowcases,
  showShowcaseDescription,
  mode,
  image,
  title,
}: TProps) => (
  <ImagePanelContainer className={`${mode}`}>
    <ImageContainer className={`${mode}`}>
      <Image
        className={`${mode}`}
        src={mode === Mode.preview ? image : PicturePlaceholder}
        alt=""
      />
    </ImageContainer>
    {(showTitleOfShowcases || showShowcaseDescription) && (
      <TextContainer>
        {showTitleOfShowcases && (
          <Typography color="#000000">
            {mode === Mode.preview ? title : "Title comes here"}
          </Typography>
        )}
        {showShowcaseDescription && (
          <Typography variant="subtitle1" textAlign="left">
            {mode === Mode.preview ? description : "Some description"}
          </Typography>
        )}
      </TextContainer>
    )}
  </ImagePanelContainer>
);

export default ImagePanels;
