import { styled } from "@mui/material";
import CoverPlaceholder from "assests/icons/picture-placeholder.svg";
import { DisplayType, Mode } from "pages/CoverCarousel";

interface TitleItem {
  coverUrl?: {
    small?: string;
    medium?: string;
    large?: string;
  } | null;
  title?: string;
  author?: string | null;
}

interface TitleItemProps extends TitleItem {
  displayType?: DisplayType;
  mode?: Mode;
}

const ItemContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
  gap: "8px",
}));

const CoverContainer = styled("div")(({ className }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#e2f1fa",
  borderRadius: "2px",
  ...(className?.includes(Mode.draft) && {
    width: "80px",
    height: "124px",
    border: "2px dotted #aad5ee",
    ...(className?.includes(DisplayType.styled) && {
      width: "64px",
      height: "96px",
      borderRadius: "0px",
      boxShadow: "0 48px 0 10px #f1f1f1, 0 64px 0 10px #f1f1f1",
    }),
  }),
  ...(className?.includes(Mode.preview) && {
    width: "96px",
    height: "136px",
    border: 0,
    ...(className?.includes(DisplayType.styled) && {
      width: "80px",
      height: "124px",
      borderRadius: "0px",
      boxShadow: "0 80px 0 16px #f1f1f1, 0 124px 0 16px #f1f1f1",
    }),
  }),
}));

const Image = styled("img")(({ className }) => ({
  ...(!className?.includes("placeholder") && { width: "100%", height: "100%" }),
}));

const TextContainer = styled("div")(({ className }) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: "8px 0",
  ...(className?.includes(Mode.draft) && {
    width: "80px",
    ...(className?.includes(DisplayType.styled) && {
      width: "64px",
    }),
  }),
  ...(className?.includes(Mode.preview) && {
    width: "96px",
    ...(className?.includes(DisplayType.styled) && {
      width: "80px",
    }),
  }),
}));

const TitleContainer = styled("div")(({ className }) => ({
  textAlign: "center",
  height: "60px",
  ...(className?.includes(Mode.draft) && {
    height: "40px",
  }),
}));

const AuthorContainer = styled("div")(({ className }) => ({
  display: "flex",
  alignItems: "end",
  justifyContent: "center",
  textAlign: "center",
  lineHeight: "20px",
  height: "24px",
  ...(className?.includes(Mode.draft) && {
    height: "12px",
  }),
}));

const Title = styled("p")(({ theme }) => ({
  ...theme.typography.subtitle1,
  lineHeight: "20px",
  display: "-webkit-box",
  "-webkit-line-clamp": "3 !important",
  "-webkit-box-orient": "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
}));

const Author = styled("p")(({ theme }) => ({
  ...theme.typography.body2,
  lineHeight: "12px",
  fontWeight: "400",
  color: "#6C6C6C",
  display: "-webkit-box",
  "-webkit-line-clamp": "2 !important",
  "-webkit-box-orient": "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
}));

// eslint-disable-next-line @typescript-eslint/no-redeclare
const TitleItem = ({ title, author, coverUrl, displayType, mode }: TitleItemProps) => {
  const type = displayType ?? DisplayType.plain;

  return (
    <ItemContainer className={`${mode}`}>
      <CoverContainer className={`${mode} ${type}`}>
        <Image
          src={coverUrl?.small ?? CoverPlaceholder}
          className={`${coverUrl?.small ?? `placeholder`}`}
        />
      </CoverContainer>
      <TextContainer className={`${mode} ${type}`}>
        <TitleContainer className={`${mode} ${type}`}>
          <Title title={title} className={`${mode} ${type}`}>
            {title ?? ""}
          </Title>
        </TitleContainer>
        <AuthorContainer className={`${mode} ${type}`}>
          <Author className={`${mode} ${type}`}>{author ?? ""}</Author>
        </AuthorContainer>
      </TextContainer>
    </ItemContainer>
  );
};

export default TitleItem;
