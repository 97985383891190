import { Stack, Typography, styled } from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { RootState } from "app/store";
import { ReactComponent as CollagePanelSvg } from "assests/icons/collage-panel.svg";
import { ReactComponent as CoverPillSvg } from "assests/icons/cover-pill.svg";
import { ReactComponent as ImagePanelSvg } from "assests/icons/image-panel.svg";
import { ReactComponent as ImagePillSvg } from "assests/icons/image-pill.svg";
import PageView from "layouts/PageView";
import RightSidebar from "layouts/RightSidebar";
import { Mode } from "pages/CoverCarousel";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  addNewCollagePanel,
  addNewCoverPill,
  addNewImagePanel,
  addNewImagePill,
  deleteCollagePanel,
  deleteCoverPill,
  deleteImagePanel,
  deleteImagePill,
} from "store/activePillSlice";
import { changeComponent, changeType } from "store/showcaseTypesSlice";
import { showSidenav } from "store/sideNavController";
import { HorizontalShowcaseContainer, ShowcaseGroupContainer } from "styles/common/ShowcaseGroup";

import ShowcaseButton from "components/Buttons/ShowCaseButton/ShowcaseButton";
import SmallCard from "components/CustomCards/SmallCard/SmallCard";
import CustomizedAccordions from "components/CustomizedAccordion/CustomizedAccordion";
import Input from "components/Input/Input";
import PageBlock from "components/PageBlock/PageBlock";
import SectionTitle from "components/SectionTitle/SectionTitle";
import SwitchSelector from "components/SwitchSelector/SwitchSelector";

const ShowcaseButtonContainer = styled("div")(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "1rem",
  marginTop: "1rem",
}));

export enum DisplayType {
  coverPill = "cover-pill",
  imagePills = "image-pills",
  collagePanel = "collage-panel",
  imagePanels = "image-panels",
}

enum SwitchOptions {
  showBlockTitle = "showBlockTitle",
  showTitleOfShowcases = "showTitleOfShowcases",
  showShowcaseDescription = "showShowcaseDescription",
}

export type DisplayTypeSettings = {
  type: DisplayType;
  title: string;
  icon: any;
  maxPills: number;
  minPills: number;
  blockSettings: {
    [key in SwitchOptions]?: any;
  };
};
interface BlockSettingsType {
  [DisplayType.coverPill]: DisplayTypeSettings;
  [DisplayType.imagePills]: DisplayTypeSettings;
  [DisplayType.collagePanel]: DisplayTypeSettings;
  [DisplayType.imagePanels]: DisplayTypeSettings;
}

const initialBlockSettings: BlockSettingsType = {
  [DisplayType.coverPill]: {
    type: DisplayType.coverPill,
    title: "Cover Pill",
    icon: CoverPillSvg,
    maxPills: 8,
    minPills: 3,
    blockSettings: {
      [SwitchOptions.showBlockTitle]: true,
    },
  },
  [DisplayType.imagePills]: {
    type: DisplayType.imagePills,
    title: "Image Pills",
    icon: ImagePillSvg,
    maxPills: 8,
    minPills: 3,
    blockSettings: {
      [SwitchOptions.showBlockTitle]: true,
    },
  },
  [DisplayType.collagePanel]: {
    type: DisplayType.collagePanel,
    title: "Collage Panel",
    icon: CollagePanelSvg,
    maxPills: 3,
    minPills: 3,
    blockSettings: {
      [SwitchOptions.showBlockTitle]: true,
      [SwitchOptions.showTitleOfShowcases]: true,
      [SwitchOptions.showShowcaseDescription]: true,
    },
  },
  [DisplayType.imagePanels]: {
    type: DisplayType.imagePanels,
    title: "Image Panels",
    icon: ImagePanelSvg,
    maxPills: 3,
    minPills: 3,
    blockSettings: {
      [SwitchOptions.showBlockTitle]: true,
      [SwitchOptions.showTitleOfShowcases]: true,
      [SwitchOptions.showShowcaseDescription]: true,
    },
  },
};

const ShowcaseGroup = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showcaseTitle, setShowcaseTitle] = useState<string>("");
  const [displayType, setDisplayType] = useState(DisplayType.coverPill);
  const [settings, setSettings] = useState(initialBlockSettings);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(changeComponent("showcase"));
  }, []);

  useEffect(() => {
    dispatch(changeType(displayType));
    dispatch(showSidenav());
  }, [displayType]);

  const coverPillData = useAppSelector((state: RootState) => state.activePill.coverPillData);
  const imagePillData = useAppSelector((state: RootState) => state.activePill.imagePillData);
  const collagePanelData = useAppSelector((state: RootState) => state.activePill.collagePanelData);
  const imagePanelData = useAppSelector((state: RootState) => state.activePill.imagePanelData);
  const showCaseButtonDisabled = true;
  const navigate = useNavigate();

  const toggleOption = (option: SwitchOptions) => {
    const newSettings = { ...settings };
    newSettings[displayType].blockSettings[option] = !settings[displayType].blockSettings[option];
    setSettings(newSettings);
  };

  const handleShowCaseTitle = (e: any) => {
    setShowcaseTitle(e);
  };

  const activeData = (data: DisplayType) => {
    switch (data) {
      case DisplayType.coverPill:
        return coverPillData;
      case DisplayType.imagePills:
        return imagePillData;
      case DisplayType.collagePanel:
        return collagePanelData;
      case DisplayType.imagePanels:
        return imagePanelData;
      default:
        return coverPillData;
    }
  };

  const addActiveShowcase = (data: DisplayType) => {
    switch (data) {
      case DisplayType.coverPill:
        return addNewCoverPill;
      case DisplayType.imagePills:
        return addNewImagePill;
      case DisplayType.collagePanel:
        return addNewCollagePanel;
      case DisplayType.imagePanels:
        return addNewImagePanel;
      default:
        return addNewCoverPill;
    }
  };
  const deleteActiveShowcase = (data: DisplayType) => {
    switch (data) {
      case DisplayType.coverPill:
        return deleteCoverPill;
      case DisplayType.imagePills:
        return deleteImagePill;
      case DisplayType.collagePanel:
        return deleteCollagePanel;
      case DisplayType.imagePanels:
        return deleteImagePanel;
      default:
        return deleteCoverPill;
    }
  };

  const handleRedirect = () => {
    if (!showCaseButtonDisabled) {
      //send post request here
      navigate("/");
    }
  };

  return (
    <>
      <PageView>
        <ShowcaseGroupContainer>
          {settings[displayType].blockSettings[SwitchOptions.showBlockTitle] && (
            <Typography sx={{ textAlign: "left", width: "100%" }} variant="subtitle2">
              [Title of showcase block]
            </Typography>
          )}
          <HorizontalShowcaseContainer>
            <PageBlock
              blockType={displayType}
              data={activeData(displayType)}
              mode={Mode.draft}
              showTitleOfShowcases={
                settings[displayType].blockSettings[SwitchOptions.showTitleOfShowcases]
              }
              showShowcaseDescription={
                settings[displayType].blockSettings[SwitchOptions.showShowcaseDescription]
              }
            />
          </HorizontalShowcaseContainer>
        </ShowcaseGroupContainer>
        <ShowcaseButtonContainer>
          <ShowcaseButton
            label="Create Showcase Block"
            onClick={handleRedirect}
            disabled={showCaseButtonDisabled}
          />
        </ShowcaseButtonContainer>
      </PageView>
      <RightSidebar>
        <SectionTitle>Select Display Type</SectionTitle>
        <Stack direction="row" spacing="24px" sx={{ width: "100%" }}>
          {Object.values(settings).map((item) => {
            const isSelected = displayType === item.type;
            return (
              <SmallCard
                title={item.title}
                icon={item.icon}
                isActive={isSelected}
                onClick={() => setDisplayType(item.type)}
              />
            );
          })}
        </Stack>
        <SectionTitle>Select Showcase</SectionTitle>
        <CustomizedAccordions
          data={activeData(displayType)}
          displayType={displayType}
          handleDelete={(id: number) => dispatch(deleteActiveShowcase(displayType)({ id }))}
          showDelete={settings[displayType].minPills < activeData(displayType).length}
        />
        {settings[displayType].maxPills > activeData(displayType).length && (
          <ShowcaseButton
            label="Add Another Showcase"
            onClick={() => dispatch(addActiveShowcase(displayType)())}
          />
        )}
        <SectionTitle>Showcase Block Settings</SectionTitle>
        <Input
          label="Title of showcase block"
          placeholder="Enter title"
          onChange={handleShowCaseTitle}
          value={showcaseTitle}
        />

        <SwitchSelector
          label="Show title of showcase block?"
          defaultChecked={settings?.[displayType]?.blockSettings?.[SwitchOptions.showBlockTitle]}
          onSwitch={() => toggleOption(SwitchOptions.showBlockTitle)}
        />
        {(displayType === "collage-panel" || displayType === "image-panels") && (
          <SwitchSelector
            label="Show title of showcases?"
            defaultChecked={
              settings?.[displayType]?.blockSettings?.[SwitchOptions.showTitleOfShowcases]
            }
            onSwitch={() => toggleOption(SwitchOptions.showTitleOfShowcases)}
          />
        )}
        {(displayType === "collage-panel" || displayType === "image-panels") && (
          <SwitchSelector
            label="Show the showcases description?"
            defaultChecked={
              settings?.[displayType]?.blockSettings?.[SwitchOptions.showShowcaseDescription]
            }
            onSwitch={() => toggleOption(SwitchOptions.showShowcaseDescription)}
          />
        )}
      </RightSidebar>
    </>
  );
};

export default ShowcaseGroup;
