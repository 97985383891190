export interface ComponentsInterface {
  AUTHOR: "AUTHORS";
  COVER_CAROUSEL: "COVER_CAROUSEL";
  EVENTS: "EVENTS";
  HERO_CAROUSEL: "HERO_CAROUSEL";
  SHOWCASE_GROUP: "SHOWCASE_GROUP";
}

export enum PageStatus {
  PUBLISHED = "PUBLISHED",
  PREVIEW = "PREVIEW",
  DRAFT = "DRAFT",
  DRAFTLIVE = "DRAFTLIVE",
  LIVE = "LIVE",
  EMPTY = "EMPTY",
}

export enum PageStatusName {
  PUBLISHED = "Published",
  DRAFTLIVE = "DRAFTLIVE",
  PREVIEW = "Preview",
  DRAFT = "Draft",
  LIVE = "Live",
  EMPTY = "Draft",
}

export interface ShowcaseItem {
  id: number;
  title: string;
  type: string;
}

export enum Components {
  AUTHOR = "/authors",
  COVER_CAROUSEL = "/cover-carousel",
  EVENTS = "/events",
  HERO_CAROUSEL = "/hero-carousel",
  SHOWCASE_GROUP = "/showcase-group",
}

export interface NavigationHeader {
  name: string;
  link: string;
}

export enum Color {
  PUBLISHED = "success",
  LIVE = "success",
  DRAFT = "warning",
  PREVIEW = "warning",
}

export function getEnumKeyByEnumValue<TEnumKey extends string, TEnumVal extends string>(
  myEnum: { [key in TEnumKey]: TEnumVal },
  enumValue: TEnumVal,
): string {
  const items = Object.keys(myEnum).indexOf(enumValue);
  return Object.values(myEnum)[items] as string;
}

export enum ShowcaseSets {
  all = "all",
  myShowcases = "myShowcases",
}

export type ShowcaseSet = {
  type: ShowcaseSets;
  title: string;
};

export type ShowcaseSettings = {
  [key in ShowcaseSets]: ShowcaseSet;
};

export enum SwitchOptions {
  showBlockTitle = "showBlockTitle",
  showResourceTitle = "showResourceTitle",
  showContributorName = "showContributorName",
}

export const ShowcaseSetOptions: ShowcaseSettings = {
  [ShowcaseSets.all]: {
    type: ShowcaseSets.all,
    title: "All",
  },
  [ShowcaseSets.myShowcases]: {
    type: ShowcaseSets.myShowcases,
    title: "My Showcases",
  },
};
