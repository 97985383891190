import { createSlice } from "@reduxjs/toolkit";

export interface ShowcaseTypesState {
  component: string;
  type: string;
}

const initialState: ShowcaseTypesState = {
  component: "cover-carousel",
  type: "cover-pill",
};

export const showcaseTypesSlice = createSlice({
  name: "showcase-types",
  initialState,
  reducers: {
    changeComponent: (state, action) => {
      state.component = action.payload;
    },
    changeType: (state, action) => {
      state.type = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeType, changeComponent } = showcaseTypesSlice.actions;

export default showcaseTypesSlice.reducer;
