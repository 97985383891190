import { Typography, styled } from "@mui/material";

import "./ShowcaseButton.scoped.scss";

interface ShowCaseButtonProps {
  onClick: () => void;
  label: string;
  disabled?: boolean;
}

interface Props {
  disabled?: boolean;
}
const ShowcaseButtonContainer = styled("div")((props: Props) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  padding: "20px 13px",
  height: "40px",
  width: "262px",
  background: `${props.disabled ? "#CDCDCD" : "#0075E3"}`,
  boxShadow: `${
    !props.disabled && "0 1px 6px rgba(0, 134, 255, 0.14), 0 1px 3px rgba(0, 134, 255, 0.03)"
  }`,
  borderRadius: "4px",
  cursor: "pointer",
}));

function ShowcaseButton({ onClick, label, disabled }: ShowCaseButtonProps) {
  return (
    <ShowcaseButtonContainer onClick={onClick} disabled={disabled}>
      <Typography variant="button">{label}</Typography>
    </ShowcaseButtonContainer>
  );
}

export default ShowcaseButton;
