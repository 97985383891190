import { Box, Typography } from "@mui/material";

import "./EventItem.scoped.scss";

type TProps = {
  title: string;
  description: string;
  image: string;
};

function EventItem({ title, description, image }: TProps) {
  return (
    <Box
      className="events-item"
      sx={{
        backgroundImage: `url(${image})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div className="event-item-background" />
      <div className="events-item-date">
        <div className="events-item-day">28</div>
        <div className="events-item-month">April</div>
      </div>
      <div className="events-item-content">
        <div className="events-item-title">
          <Typography variant="h3" component="h2" sx={{ marginBottom: 0.5 }}>
            {title}
          </Typography>
        </div>
        <div className="events-item-description">
          <Typography variant="body1" sx={{ marginBottom: 2 }}>
            {description}
          </Typography>
        </div>
        <div className="events-item-info">
          <Typography variant="body2" component="h2">
            10:00 AM - 1:00 AM - MayBerry Library
          </Typography>
        </div>
      </div>
    </Box>
  );
}

export default EventItem;
