import { styled, Box, Typography } from "@mui/material";
import * as React from "react";
import { ReactNode, useState, Children } from "react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Swiper, SwiperSlide } from "swiper/react";
import { SwiperModule } from "swiper/types/shared";
import SwiperClass from "swiper/types/swiper-class";

interface Props {
  modules?: SwiperModule[];
  slidesPerView: number;
  spaceBetween: number;
  navigation: boolean;
  children: ReactNode;
  // distance?: number;
  // onSlideChange?: (swiper: SwiperClass) => void;
  noPagination?: boolean;
  config?: any;
}

const Dot = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isActive",
})<{ isActive?: boolean }>(({ isActive }) => ({
  height: "14px",
  width: "14px",
  borderRadius: "50%",
  backgroundColor: "#B6B6B6",
  ...(isActive && {
    backgroundColor: "#0280B3",
  }),
}));

const PaginationContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  gap: "14px",
  padding: "24px 0",
}));

const HeroCarousel = ({
  modules,
  slidesPerView = 1,
  spaceBetween = 0,
  navigation,
  children,
  noPagination,
  config,
}: Props) => {
  const [slideIndex, setSlideIndex] = useState<number>(0);

  const onSlideChange = ({ realIndex }: SwiperClass) => setSlideIndex(realIndex);

  return (
    <>
      {config?.showBlockTitle && (
        <Typography sx={{ textAlign: "left", width: "100%", padding: "24px 0" }} variant="h6">
          [Title of showcase block]
        </Typography>
      )}
      <Swiper
        className="slider"
        modules={modules}
        spaceBetween={spaceBetween}
        slidesPerView={slidesPerView}
        navigation={navigation}
        pagination={{ clickable: true }}
        // onSwiper={setInstance}
        onSlideChange={onSlideChange}
      >
        {Children.map(children, (child) => (
          <SwiperSlide>{child}</SwiperSlide>
        ))}
      </Swiper>
      {!noPagination && (
        <PaginationContainer>
          {Children.map(children, (_, index) => {
            const isActive = slideIndex === index;

            return <Dot isActive={isActive} />;
          })}
        </PaginationContainer>
      )}
    </>
  );
};

export default HeroCarousel;
