import { Box } from "@mui/material";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { Stack } from "@mui/system";
import { useAppDispatch } from "app/hooks";
import { ReactComponent as DeleteIcon } from "assests/icons/delete-icon.svg";
import { ReactComponent as EditIcon } from "assests/icons/edit-icon.svg";
import { DisplayType } from "pages/ShowcaseGroup";
import * as React from "react";
import { setActivePill } from "store/activePillSlice";

import OverlayColor from "../OverlayColor/OverlayColor";
import SearchShowcases from "../SearchShowcases/SearchShowcases";
import UploadImage from "../UploadImage/UploadImage";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion
    sx={{ border: props.expanded ? "1px solid #55ADFF" : "1px solid #BCBCBC" }}
    disableGutters
    elevation={0}
    square
    {...props}
  />
))(() => ({
  marginBottom: "20px",
  borderRadius: "8px",
  "&:before": {
    display: "none",
  },
  "&:last-child": {
    marginBottom: "0",
  },
}));

const AccordionSummary = styled((props: any) => <MuiAccordionSummary {...props} />)(() => ({
  height: "40px",
  minHeight: "40px",
  display: "flex",
  justifyContent: "space-between",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid #BCBCBC",
  borderRadius: "0px 0 8px 8px",
  backgroundColor: "#F9F9F9",
  display: "flex",
  flexDirection: "column",
  gap: "24px",
}));

type TProps = {
  data: any;
  displayType: DisplayType;
  handleDelete: any;
  showDelete: boolean;
};

export default function CustomizedAccordions({
  data,
  displayType,
  handleDelete,
  showDelete,
}: TProps) {
  const [expanded, setExpanded] = React.useState<string | false>("panel1");

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (expanded) {
      const activeId = expanded.split("panel")[1];
      dispatch(setActivePill(activeId));
    }
  }, [expanded, handleDelete]);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      {data.map((accordion: any) => (
        <Accordion
          expanded={expanded === `panel${accordion.id}`}
          onChange={handleChange(`panel${accordion.id}`)}
        >
          <AccordionSummary
            expanded={expanded === `panel${accordion.id}`}
            aria-controls={`panel${accordion.id}-content`}
            id={`panel${accordion.id}d-header`}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Stack direction="row" gap="5px">
                <Typography variant="subtitle2">Pill {accordion.id}</Typography>
                {accordion.title && (
                  <Typography variant="subtitle2">({accordion.title})</Typography>
                )}
              </Stack>
              <Stack direction="row" gap="14px">
                <EditIcon />
                {showDelete && <DeleteIcon onClick={() => handleDelete(accordion.id)} />}
              </Stack>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <SearchShowcases index={accordion.id} />
            {(displayType === "image-pills" || displayType === "image-panels") && (
              <UploadImage image={accordion.image} displayType={displayType} />
            )}
            {displayType === "image-pills" && <OverlayColor />}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
