import { Typography, styled } from "@mui/material";
import PicturePlaceholder from "assests/icons/picture-placeholder.svg";
import { Mode } from "pages/CoverCarousel";
import React from "react";

type TProps = {
  mode: string;
  image?: string;
  title?: string;
  overlay?: string;
};

const ImagePillContainer = styled("div")(({ className }) => ({
  background: "#E2F1FA",
  border: "0.469974px dashed #AAD5EE",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  ...(className?.includes(Mode.draft) && {
    minWidth: "176px",
    height: "80px",
  }),
  ...(className?.includes(Mode.preview) && {
    minWidth: "255px",
    height: "85px",
  }),
}));

const Image = styled("img")(({ className }) => ({
  textAlign: "center",
  ...(className?.includes(Mode.preview) && {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  }),
}));

const Overlay = styled("div")(({ className }) => ({
  width: "100%",
  height: "100%",
  mixBlendMode: "multiply",
  position: "absolute",
  left: "50%",
  transform: "translateX(-50%)",
  borderRadius: "4px",
  backgroundColor: className,
}));

const CustomizedTypography = styled(Typography)(({ className }) => ({
  position: "absolute",
  left: "50%",
  transform: "translateX(-50%)",
  ...(className?.includes(Mode.preview) && {
    color: "#fff",
    fontSize: "20px",
    fontWeight: 500,
  }),
}));

const ImagePill = ({ mode, image, title, overlay }: TProps) => (
  <ImagePillContainer className={`${mode}`}>
    <Overlay className={`${overlay}`} />
    <Image className={`${mode}`} src={image || PicturePlaceholder} />
    <CustomizedTypography className={`${mode}`} variant="subtitle1">
      {mode === Mode.preview ? title : "Title"}
    </CustomizedTypography>
  </ImagePillContainer>
);

export default ImagePill;
