import { createTheme } from "@mui/material/styles";

import { colors } from "./colors";

const theme = createTheme({
  typography: {
    fontFamily: ["GT America", "Helvetica", "Arial", "sans-serif", "-apple-system"].join(","),
    subtitle1: {
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "16px",
      textAlign: "center",
    },
    subtitle2: {
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "14px",
      lineHeight: "16px",
      textAlign: "center",
    },
    h1: {
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "35px",
    },
    h2: {
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "30px",
    },
    h3: {
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "28px",
      color: "white",
    },
    h4: {
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "25px",
    },
    h5: {
      fontStyle: "normal",
      fontWeight: 400,
      textAlign: "center",
      fontSize: "20px",
    },
    h6: {
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "18px",
    },
    body1: {
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "14px",
    },
    body2: {
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "12px",
    },
    button: {
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "15px",
      color: "white",
      textTransform: "none",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "*": {
          margin: 0,
          padding: 0,
          boxSizing: "border-box",
        },
        "#root, html, body": {
          flex: 1,
          display: "flex",
          width: "100%",
          height: "100%",
        },
      },
    },
  },
  palette: {
    action: {
      disabled: "#000000",
    },
    background: {
      default: colors.white,
    },
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.lightGray,
    },
    warning: {
      main: colors.warning,
    },
    success: {
      main: colors.green,
      light: colors.greenLighter,
    },
    error: {
      main: colors.danger,
    },
    mode: "light",
  },
  shape: {
    borderRadius: 8,
  },
});

export default theme;
