import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const getFileFromURL = async (url: string, name: string) => {
  const blob = await fetch(url)
    .then((r) => r.blob())
    .then((data) => data);
  return new File([blob], name, { type: blob.type });
};

interface PageOptions {
  status: string;
}

interface BlockOptions {
  pageId: string;
  blockTypeId: string;
  displayTypeId: string;
  blockTitle: string;
  blockOrder: number;
  showBlockTitle: boolean;
  showResourceTitles: boolean;
  showContributorName: boolean;
  showGenre: boolean;
  showAuthDesc: boolean;
}

export const apiSlice = createApi({
  reducerPath: "web-service-api",
  baseQuery: fetchBaseQuery({
    prepareHeaders(headers) {
      headers.set("Accept", "*/*");

      return headers;
    },
  }),
  endpoints(builder) {
    return {
      fetchPage: builder.query<any, PageOptions>({
        query({ status = "draft" }) {
          return `/customerPage/current?status=${status}`;
        },
      }),
      createPage: builder.mutation<any, PageOptions>({
        query({ status }) {
          return {
            url: `/customerPage/current?status=${status}`,
            method: "POST",
          };
        },
      }),
      createPageBlock: builder.mutation<any, BlockOptions>({
        query(data) {
          return {
            url: `/pageBlock`,
            method: "POST",
            data,
          };
        },
      }),
      createPageBlockItem: builder.mutation<any, any>({
        async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
          const { fileURL, fileName, typeId, itemOrder, pageBlockId } = _arg;
          const data = new FormData();

          data.append("image", await getFileFromURL(fileURL, fileName));
          data.append("type_id", typeId);
          data.append("item_order", itemOrder);
          data.append("page_block_id", pageBlockId);
          const response = await fetchWithBQ({ url: "/pageBlockItem", method: "POST", body: data });
          return response;
        },
      }),
    };
  },
});

export const { useFetchPageQuery, useCreatePageBlockItemMutation } = apiSlice;
