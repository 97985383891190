import { useAppDispatch } from "app/hooks";
import { ContainerWrapper } from "layouts/PageView";
import { useEffect } from "react";
import { previewPage } from "store/homeConfiguration";
import { hideSidenav } from "store/sideNavController";

//import HomePageHeader from "components/HomePage/HomePageHeader";
//import Multistep from "components/Multistep/Multistep";
import PreviewPage from "components/Preview/Preview";

const Preview = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(hideSidenav());
    dispatch(previewPage());
  }, []);
  return (
    <ContainerWrapper>
      <PreviewPage />
    </ContainerWrapper>
  );
};

export default Preview;
