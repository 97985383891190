import { Typography, styled } from "@mui/material";
import PicturePlaceholder from "assests/icons/picture-placeholder.svg";
import { Mode } from "pages/CoverCarousel";
import TextContainer from "styles/common/TextContainer";

type TProps = {
  description: string;
  images: string[];
  showTitleOfShowcases?: boolean;
  showShowcaseDescription?: boolean;
  mode: string;
  title?: string;
};

const CollagePanelContainer = styled("div")(({ className }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "16px",
  height: "auto",
  background: "#FFFFFF",
  ...(className?.includes(Mode.draft) && {
    width: "196px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.15)",
  }),
  ...(className?.includes(Mode.preview) && {
    width: "432px",
    // height: "auto",
  }),
}));

const CollageImages = styled("div")(({ className }) => ({
  display: "grid",
  gridTemplateColumns: "auto auto auto auto",
  gridTemplateRows: "auto auto",
  width: "100%",
  ...(className?.includes(Mode.draft) && {
    height: "193px",
  }),
  ...(className?.includes(Mode.preview) && {
    height: "289px",
  }),
}));

const ImageContainer = styled("div")(({ className }) => ({
  background: "#E2F1FA",
  border: "0.630192px dashed #AAD5EE",
  borderRadius: "1.68051px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  ...(className?.includes(Mode.draft) && {
    width: "49px",
    height: "96.5px",
  }),
  ...(className?.includes(Mode.preview) && {
    height: "100%",
    width: "100%",
  }),
}));

const Image = styled("img")(({ className }) => ({
  ...(className?.includes(Mode.preview) && {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  }),
}));

const CollagePanel = ({
  description,
  images,
  showTitleOfShowcases,
  showShowcaseDescription,
  mode,
  title,
}: TProps) => (
  <CollagePanelContainer className={`${mode}`}>
    <CollageImages className={`${mode}`}>
      {images.map((image, index) => (
        <ImageContainer key={index} className={`${mode}`}>
          <Image
            className={`${mode}`}
            src={mode === Mode.draft ? PicturePlaceholder : image}
            // src={PicturePlaceholder}
            alt=""
          />
        </ImageContainer>
      ))}
    </CollageImages>
    {(showTitleOfShowcases || showShowcaseDescription) && (
      <TextContainer>
        {showTitleOfShowcases && (
          <Typography color="#000000"> {mode === Mode.preview ? title : "Title"}</Typography>
        )}
        {showShowcaseDescription && (
          <Typography variant="subtitle1" textAlign="left">
            {mode === Mode.preview ? description : "Some description"}
          </Typography>
        )}
      </TextContainer>
    )}
  </CollagePanelContainer>
);

export default CollagePanel;
