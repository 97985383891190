/* eslint-disable eqeqeq */
import { createSlice } from "@reduxjs/toolkit";
import {
  CollagePanelMockData,
  CoverPillMockData,
  ImagePanelsMockData,
  ImagePillsMockData,
} from "__mocks__/ShowcaseGroupMocks";

type TCoverPill = {
  id: number;
  images: string[];
  title: string;
};

type TImagePill = {
  id: number;
  image: any;
  title: string;
  overlay: {
    color: string;
    name: string;
  };
};

type TCollagePanel = {
  id: number;
  images: string[];
  title: string;
  description: string;
};

type TImagePanel = {
  id: number;
  image: string;
  title: string;
  description: string;
};

export interface ActivePillState {
  activePill: number;
  coverPillData: TCoverPill[];
  imagePillData: TImagePill[];
  collagePanelData: TCollagePanel[];
  imagePanelData: TImagePanel[];
}

const initialState: ActivePillState = {
  activePill: 0,
  coverPillData: CoverPillMockData,
  imagePillData: ImagePillsMockData,
  collagePanelData: CollagePanelMockData,
  imagePanelData: ImagePanelsMockData,
};

export const activePillSlice = createSlice({
  name: "active-pill",
  initialState,
  reducers: {
    setActivePill: (state, action) => {
      state.activePill = action.payload;
    },
    setCoverPillData: (state, action) => {
      state.coverPillData = state.coverPillData.map((element) => {
        if (element.id == action.payload.id) {
          return {
            ...element,
            title: action.payload.title,
          };
        }
        return element;
      });
    },
    addNewCoverPill: (state) => {
      const addedShowcase = {
        ...CoverPillMockData[0],
        id: state.coverPillData[state.coverPillData.length - 1].id + 1,
      };
      state.coverPillData = [...state.coverPillData, addedShowcase];
    },
    deleteCoverPill: (state, action) => {
      const filteredCoverPill = state.coverPillData.filter(
        (element: any) => element.id != action.payload.id,
      );
      state.coverPillData = filteredCoverPill;
    },
    setImagePillData: (state, action) => {
      state.imagePillData = state.imagePillData.map((element) => {
        if (element.id == action.payload.id) {
          return {
            ...element,
            title: action.payload.title,
          };
        }
        return element;
      });
    },
    setImagePillOverlay: (state, action) => {
      state.imagePillData = state.imagePillData.map((element) => {
        if (element.id == action.payload.id) {
          return {
            ...element,
            overlay: action.payload.overlay,
          };
        }
        return element;
      });
    },
    setImagePillImage: (state, action) => {
      state.imagePillData = state.imagePillData.map((element) => {
        if (element.id == action.payload.id) {
          return {
            ...element,
            image: action.payload.image,
          };
        }
        return element;
      });
    },

    addNewImagePill: (state) => {
      const addedShowcase = {
        ...ImagePillsMockData[0],
        id: state.imagePillData[state.imagePillData.length - 1].id + 1,
      };
      state.imagePillData = [...state.imagePillData, addedShowcase];
    },
    deleteImagePill: (state, action) => {
      const filteredImagePill = state.imagePillData.filter(
        (element: any) => element.id != action.payload.id,
      );
      state.imagePillData = filteredImagePill;
    },
    setCollagePanelData: (state, action) => {
      state.collagePanelData = state.collagePanelData.map((element) => {
        if (element.id == action.payload.id) {
          return {
            ...element,
            title: action.payload.title,
          };
        }
        return element;
      });
    },
    addNewCollagePanel: (state) => {
      const addedShowcase = {
        ...CollagePanelMockData[0],
        id: state.collagePanelData[state.collagePanelData.length - 1].id + 1,
      };
      state.collagePanelData = [...state.collagePanelData, addedShowcase];
    },
    deleteCollagePanel: (state, action) => {
      const filteredCollagePanel = state.collagePanelData.filter(
        (element: any) => element.id != action.payload.id,
      );
      state.collagePanelData = filteredCollagePanel;
    },
    setImagePanelData: (state, action) => {
      state.imagePanelData = state.imagePanelData.map((element) => {
        if (element.id == action.payload.id) {
          return {
            ...element,
            title: action.payload.title,
          };
        }
        return element;
      });
    },
    setImagePanelImage: (state, action) => {
      state.imagePanelData = state.imagePanelData.map((element) => {
        if (element.id == action.payload.id) {
          return {
            ...element,
            image: action.payload.image,
          };
        }
        return element;
      });
    },
    addNewImagePanel: (state) => {
      const addedShowcase = {
        ...ImagePanelsMockData[0],
        id: state.imagePanelData[state.imagePanelData.length - 1].id + 1,
      };
      state.imagePanelData = [...state.imagePanelData, addedShowcase];
    },
    deleteImagePanel: (state, action) => {
      const filteredImagePanel = state.imagePanelData.filter(
        (element: any) => element.id != action.payload.id,
      );
      state.imagePanelData = filteredImagePanel;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setActivePill,
  setCoverPillData,
  setImagePillData,
  setCollagePanelData,
  setImagePanelData,
  addNewCoverPill,
  addNewCollagePanel,
  addNewImagePanel,
  addNewImagePill,
  setImagePillImage,
  setImagePanelImage,
  setImagePillOverlay,
  deleteCoverPill,
  deleteImagePill,
  deleteCollagePanel,
  deleteImagePanel,
} = activePillSlice.actions;

export default activePillSlice.reducer;
