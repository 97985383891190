import { Grid } from "@mui/material";
import { Mode } from "pages/CoverCarousel";
import { DisplayType } from "pages/ShowcaseGroup";
import CollagePanel from "pages/ShowcaseGroup/CollagePanel";
import CoverPill from "pages/ShowcaseGroup/CoverPill";
import ImagePanels from "pages/ShowcaseGroup/ImagePanels";
import ImagePill from "pages/ShowcaseGroup/ImagePill";

import AuthorItem from "components/AuthorItem/AuthorItem";
import EventItem from "components/EventItem/EventItem";

type TProps = {
  blockType: string;
  data: any;
  mode: Mode;
  showTitleOfShowcases?: boolean;
  showShowcaseDescription?: boolean;
};

const PageBlock = ({
  blockType,
  data,
  mode,
  showTitleOfShowcases,
  showShowcaseDescription,
}: TProps) => (
  <>
    {blockType === DisplayType.coverPill &&
      data.map((element: any) => (
        <CoverPill key={element.id} images={element.images} title={element.title} mode={mode} />
      ))}
    {blockType === DisplayType.imagePills &&
      data.map((element: any) => (
        <ImagePill
          key={element.id}
          mode={mode}
          title={element.title}
          overlay={element.overlay_color}
        />
      ))}
    {blockType === DisplayType.collagePanel &&
      data.map((element: any) => (
        <CollagePanel
          key={element.id}
          title={element.title}
          images={element.images}
          mode={mode}
          showTitleOfShowcases={showTitleOfShowcases}
          showShowcaseDescription={showShowcaseDescription}
          description={element.description}
        />
      ))}
    {blockType === DisplayType.imagePanels &&
      data.map((element: any) => (
        <ImagePanels
          key={element.id}
          title={element.title}
          image={element.image}
          mode={mode}
          showTitleOfShowcases={showTitleOfShowcases}
          showShowcaseDescription={showShowcaseDescription}
          description={element.description}
        />
      ))}
    {blockType === "authors" &&
      data.map((element: any) => (
        <Grid item xs={4}>
          <AuthorItem
            author={element.author}
            authorImage={element.image}
            preferedBook={element.images[0]}
            description={element.description}
          />
        </Grid>
      ))}
    {blockType === "events" &&
      data.map((element: any) => (
        <Grid item xs={4}>
          <EventItem
            title={element.title}
            description={element.description}
            image={element.image}
          />
        </Grid>
      ))}
  </>
);

export default PageBlock;
