import { ReactComponent as CloseIcon } from "assests/icons/close.svg";
import React from "react";
import ReactDOM from "react-dom";

import "./ModalDialog.scoped.scss";

const modalElement = document.getElementById("modal");
interface Props {
  open: boolean;
  error?: string;
  onClose: () => void;
  title?: React.ReactNode;
  isLoadingContent?: boolean;
  isLoadingActions?: boolean;
  children?: React.ReactNode;
}

const ModalDialog: React.FC<Props> = ({
  open,
  error = "",
  onClose,
  children,
  title = null,
  isLoadingContent = false,
  isLoadingActions = false,
}) => {
  if (!open) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className="modal-dialog">
      <div className="backdrop" onClick={onClose} />

      <div
        style={{ visibility: isLoadingContent ? "hidden" : "visible" }}
        className="modal"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="close-icon" onClick={onClose}>
          <CloseIcon />
        </div>

        {title && <div className="title">{title}</div>}

        {/* {isLoadingContent && (
          <div className="loaderContainer">
            //loader here
          </div>
        )} */}

        <div
          className="content"
          style={{
            height: isLoadingContent ? "1px" : "auto",
            visibility: isLoadingContent ? "hidden" : "visible",
            opacity: isLoadingActions ? "0.5" : "1",
            pointerEvents: isLoadingActions ? "none" : "auto",
          }}
        >
          {children}
        </div>

        {error.length > 0 && <div className="error">{error}</div>}
      </div>
    </div>,
    modalElement!,
  );
};

export default ModalDialog;
