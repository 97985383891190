import { Box, Stack } from "@mui/material";
import dummySearch from "__mocks__/dummyJson";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { RootState } from "app/store";
import { DisplayType } from "pages/ShowcaseGroup";
import { useEffect, useState } from "react";
import {
  setCollagePanelData,
  setCoverPillData,
  setImagePanelData,
  setImagePillData,
} from "store/activePillSlice";

import RadioButton from "components/Buttons/RadioButton/RadioButton";
import SearchDialog from "components/SearchDialog/SearchDialog";
import TypeAheadDropdown from "components/TypeAheadDropdown/TypeAheadDropdown";

enum ShowcaseSets {
  all = "all",
  myShowcases = "myShowcases",
}

type ShowcaseSettings = {
  [key in ShowcaseSets]?: {
    type: ShowcaseSets;
    title: string;
  };
};

const initialShowcaseSettings: ShowcaseSettings = {
  [ShowcaseSets.all]: {
    type: ShowcaseSets.all,
    title: "All",
  },
  [ShowcaseSets.myShowcases]: {
    type: ShowcaseSets.myShowcases,
    title: "My Showcases",
  },
};

type TProps = {
  index: number;
};

const SearchShowcases = ({ index }: TProps) => {
  const activePill = useAppSelector((state: RootState) => state.activePill.activePill);
  const displayType = useAppSelector((state: RootState) => state.showcaseTypes.type);
  const [showcaseSet, setShowcaseSet] = useState(ShowcaseSets.all + index);
  const [searchOpened, setSearchOpened] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<string>("");
  const [results, setResults] = useState([]);
  const [dropdownOpened, setDropdownOpened] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      dummySearch(searchInput).then(({ products }) => {
        setResults(products);
      });
    }, 1000);
  }, [searchInput]);

  const dispatch = useAppDispatch();

  const activeDisplayType = (type: string) => {
    switch (type) {
      case DisplayType.coverPill:
        return setCoverPillData;
      case DisplayType.imagePills:
        return setImagePillData;
      case DisplayType.collagePanel:
        return setCollagePanelData;
      case DisplayType.imagePanels:
        return setImagePanelData;
      default:
        return setCoverPillData;
    }
  };

  const onSelect = (item: any) => {
    dispatch(
      activeDisplayType(displayType)({
        id: activePill,
        title: item.target.innerText,
      }),
    );
    setSearchInput(item.target.innerText);
    setDropdownOpened(false);
    if (item === "action") {
      setSearchOpened(true);
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
      <Stack direction="row" spacing="80px" sx={{ width: "100%", fontSize: "14px" }}>
        {Object.values(initialShowcaseSettings).map(({ type, title }) => {
          const isSelected = showcaseSet === type + activePill;
          return (
            <RadioButton
              title={title}
              checked={isSelected}
              onClick={() => setShowcaseSet(type + activePill)}
            />
          );
        })}
      </Stack>
      <TypeAheadDropdown
        dropdownOpened={dropdownOpened}
        setDropdownOpened={setDropdownOpened}
        hasSuggestions
        onSelect={onSelect}
        suggestions={results}
        placeholder="Search"
        onChange={(e) => setSearchInput(e)}
        value={searchInput}
      />
      <SearchDialog
        open={searchOpened}
        onClose={() => setSearchOpened(false)}
        onSelect={onSelect}
      />
    </Box>
  );
};

export default SearchShowcases;
