import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface CoverCarouselState {
  displayType: string;
  showCaseSelected: string;
  bookSearched: string;
  showCaseTitle: string;
  displayConfig: any;
}

const initialState: CoverCarouselState = {
  displayType: "plain",
  showCaseSelected: "all",
  bookSearched: "",
  showCaseTitle: "",
  displayConfig: {
    showBlockTitle: true,
    showResourceTitle: true,
    showContributorName: true,
  },
};

export const coverCarouselSlice = createSlice({
  name: "coverCarousel",
  initialState,
  reducers: {
    updateDisplayType: (state, action: PayloadAction<string>) => {
      state.displayType = action.payload;
    },
    updateShowCaseSelected: (state, action: PayloadAction<string>) => {
      state.showCaseSelected = action.payload;
    },
    updateBookSearched: (state, action: PayloadAction<string>) => {
      state.bookSearched = action.payload;
    },
    updateShowCaseTitle: (state, action: PayloadAction<string>) => {
      state.showCaseTitle = action.payload;
    },
    updateDisplayConfig: (state, action: any) => {
      state.displayConfig =
        action.payload === "reset"
          ? initialState.displayConfig
          : {
              ...state.displayConfig,
              [action.payload]: !state.displayConfig[action.payload],
            };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateDisplayType,
  updateShowCaseSelected,
  updateBookSearched,
  updateShowCaseTitle,
  updateDisplayConfig,
} = coverCarouselSlice.actions;

export default coverCarouselSlice.reducer;
