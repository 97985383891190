import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { PageStatus, ShowcaseItem } from "../type";

const showcase: ShowcaseItem[] = [
  {
    id: 1,
    title: "Top Ten Most Challenged Books 2021",
    type: "AUTHOR",
  },
  {
    id: 2,
    title: "New in the library",
    type: "COVER_CAROUSEL",
  },
  {
    id: 3,
    title: "Events",
    type: "EVENTS",
  },
  {
    id: 4,
    title: "Hero Carousel",
    type: "HERO_CAROUSEL",
  },
  {
    id: 5,
    title: "Showcase Group",
    type: "SHOWCASE_GROUP",
  },
];

export interface CounterState {
  status: PageStatus;
  showcase: ShowcaseItem[];
}

const initialState: CounterState = {
  status: PageStatus.DRAFTLIVE,
  showcase,
};

export const homeConfigurationSlice = createSlice({
  name: "homePage",
  initialState,
  reducers: {
    publishPage: (state) => {
      state.status = PageStatus.PUBLISHED;
    },
    previewPage: (state) => {
      state.status = PageStatus.PREVIEW;
    },
    livePage: (state) => {
      state.status = PageStatus.LIVE;
    },
    draftPage: (state) => {
      state.status = PageStatus.DRAFT;
    },
    draftLivePage: (state) => {
      state.status = PageStatus.DRAFTLIVE;
    },
    emptyPage: (state) => {
      state.status = PageStatus.EMPTY;
    },
    addItem: (state, action) => {
      state.showcase = action.payload;
    },
    removeItem: (state, action: PayloadAction<number>) => {
      state.showcase = state.showcase.filter((item) => item.id !== action.payload);
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  livePage,
  draftPage,
  emptyPage,
  draftLivePage,
  publishPage,
  previewPage,
  removeItem,
} = homeConfigurationSlice.actions;

export default homeConfigurationSlice.reducer;
