import styled from "@emotion/styled";
import { Box } from "@mui/material";

import MultiStep from "components/Multistep/Multistep";

export const ContainerWrapper = styled("div")(() => ({
  marginTop: 20,
}));

function PageView({ children }: any) {
  return (
    <Box
      sx={{
        width: "100%",
        maxHeight: "calc(100vh - 75px)",
        overflowY: "scroll",
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        padding: "24px",
        alignItems: "center",
      }}
    >
      <MultiStep />
      {children}
    </Box>
  );
}

export default PageView;
