import SearchIconLarge from "assests/icons/search-icon-large.svg";
import SearchIcon from "assests/icons/search-icon.svg";
import useOnClickOutside from "hooks/useOnClickOutside";
import React, { RefObject, useRef, useState } from "react";

import SearchMoreModal from "../SearchMoreModal/SearchMoreModal";
import Suggestions from "../Suggestions/Suggestions";
import "./TypeAheadDropdown.scoped.scss";

type Suggestion = any;
interface TypeAheadDropdownProps {
  suggestions?: Suggestion[];
  large?: boolean;
  placeholder?: string;
  hasSuggestions?: boolean;
  onChange: (val: any) => void;
  value: any;
  onSelect?: (item: any) => void;
  dropdownOpened: boolean;
  setDropdownOpened: React.Dispatch<React.SetStateAction<boolean>>;
}

function TypeAheadDropdown({
  suggestions,
  large,
  placeholder,
  hasSuggestions,
  value,
  onChange,
  onSelect,
  dropdownOpened,
  setDropdownOpened,
}: TypeAheadDropdownProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const ref = useRef<HTMLDivElement>(null);
  const [inputFocused, setInputFocused] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);

  useOnClickOutside(ref, () => {
    setDropdownOpened(false);
  });

  const handleChange = (theValue: string) => {
    onChange(theValue);
    setDropdownOpened(true);
  };

  const onFocus = () => {
    setInputFocused(true);
  };

  const onBlur = () => {
    setInputFocused(false);
  };

  const pullModalData = (data: any) => {
    setModal(data);
  };

  return (
    <>
      <SearchMoreModal open={modal} onClose={() => setModal(false)} />
      <div className={`type-ahead-container ${large && "large"}`} ref={ref}>
        <div className={`search-icon-container ${inputFocused && "active"}`}>
          <img src={!large ? SearchIcon : SearchIconLarge} alt="" />
        </div>
        <div className="type-ahead-dropdown">
          <input
            ref={inputRef as RefObject<HTMLInputElement>}
            value={value}
            placeholder={placeholder}
            type="text"
            // eslint-disable-next-line @typescript-eslint/no-shadow
            onChange={({ target: { value } }) => handleChange(value)}
            onFocus={onFocus}
            onBlur={onBlur}
            className={`${inputFocused && "active"}`}
          />
          {hasSuggestions && dropdownOpened && (
            <Suggestions
              modal={pullModalData}
              suggestions={suggestions}
              active={inputFocused}
              onSelect={onSelect}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default TypeAheadDropdown;
